let map;
let geocoder;

let centerLat = 50.1121021;
let centerLng = 11.4537282;

const addresses = [
	{
		name: 'Leithner GmbH & Co KG',
		street: 'Albert-Ruckdeschel-Str. 12 a',
		zip: '95326 Kulmbach',
		web: 'www.intersport-leithner.de',
		position: {lat: 50.1121021, lng: 11.4537282}
	},
	{
		name: 'Stefan Heiden GmbH',
		street: 'Aufeld 3 A',
		zip: '95326 Kulmbach',
		web: 'www.heiden-bad.de',
		position: {lat: 50.1003233, lng: 11.403479800000014}
	},
	{
		name: 'Papier + Schreibwaren Sintenis',
		street: 'Blaicher Str. 25',
		zip: '95326 Kulmbach',
		web: 'www.sintenis-kulmbach.de',
		position: {lat: 50.11602, lng: 11.46383000000003}
	},
	{
		name: 'Blaicher Apotheke',
		street: 'Blaicher Str. 3',
		zip: '95326 Kulmbach',
		web: 'www.apotheke-in-kulmbach.de',
		position: {lat: 50.1154963, lng: 11.467942799999946}
	},
	{
		name: 'SKL Hofmann',
		street: 'Fritz-Hornschuch-Str. 4',
		zip: '95326 Kulmbach',
		web: '',
		position: {lat: 50.1080548, lng: 11.452971899999966}
	},
	{
		name: 'Uhren-Werkstatt',
		street: 'Grabenstraße 5',
		zip: '95326 Kulmbach',
		position: {lat: 50.1076, lng: 11.456670000000031}
	},
	{
		name: 'Eiscafé Sanremo',
		street: 'Marktplatz 9',
		zip: '95326 Kulmbach',
		web: 'sanremo-kulmbach.de',
		position: {lat: 50.10724, lng: 11.45771000000002}
	},
	{
		name: 'Herrenmode am Holzmarkt',
		street: 'Holzmarkt 11',
		zip: '95326 Kulmbach',
		position: {lat: 50.10772, lng: 11.454870000000028}
	},
	{
		name: 'Windisch Optik ',
		street: 'Klostergasse 2 a',
		zip: '95326 Kulmbach',
		web: 'www.windisch-optik.de',
		position: {lat: 50.1081125, lng: 11.45669440000006}
	},
	{
		name: 'Parfümerie Benker',
		street: 'Kressenstein 3',
		zip: '95326 Kulmbach',
		web: 'www.parfuemerie-benker.de',
		position: {lat: 50.10755, lng: 11.454489999999964}
	},
	{
		name: 'Hoffmann OHG Optic',
		street: 'Kressenstein 6',
		zip: '95326 Kulmbach',
		web: 'www.hoffmann-optic.eu',
		position: {lat: 50.10787, lng: 11.454320000000052}
	},
	{
		name: 'Hotel Christl',
		street: 'Bayreuther Str. 7 ',
		zip: '95326 Kulmbach',
		web: 'www.hotelchristl.de',
		position: {lat: 50.09743, lng: 11.445190000000025}
	},
	{
		name: 'Esther-Confiserie',
		street: 'Marktplatz 12',
		zip: '95326 Kulmbach',
		web: 'www.esther-confiserie.de',
		position: {lat: 50.10684999999999, lng: 11.45790999999997}
	},
	{
		name: 'Metzgerei Lauterbach',
		street: 'Marktplatz 7-8',
		zip: '95326 Kulmbach',
		web: 'www.lauterbach-kulmbach.de',
		position: {lat: 50.1073837, lng:  11.45794669999998}
	},
	{
		name: 'Der blaue Laden',
		street: 'Spitalgasse 1',
		zip: '95326 Kulmbach',
		web: 'www.der-blaue-laden-in-kulmbach.de',
		position: {lat: 50.1075174, lng: 11.458488200000033}
	},
	{
		name: 'Dreams',
		street: 'Spitalgasse 1',
		zip: '95326 Kulmbach',
		web: 'dreams-mode.de',
		position: {lat: 50.1075174, lng: 11.458488200000033}
	},
	{
		name: 'Cafe Schoberth',
		street: 'Spitalgasse 5',
		zip: '95326 Kulmbach',
		web: 'www.cafe-schoberth.de',
		position: {lat: 50.1077872, lng: 11.458220100000062}
	}
];


var customMarker = {
	path: 'M75,49.3c-6.2,0-11.3,5.1-11.3,11.3S68.8,71.8,75,71.8s11.3-5.1,11.3-11.3C86.3,54.3,81.2,49.3,75,49.3z M75,23.4c-20.6,0-37.3,16.7-37.3,37.3c0,6.8,1.9,13.5,5.4,19.3l29.2,45.1c0.6,0.9,1.6,1.5,2.7,1.5 c1.1,0,2.1-0.6,2.7-1.5L107,79.9c3.5-5.8,5.3-12.4,5.3-19.2C112.3,40.1,95.6,23.4,75,23.4z M101.5,76.5l-26.5,41L48.6,76.6c-2.9-4.8-4.4-10.3-4.4-15.9c0-17,13.8-30.9,30.9-30.9c17,0,30.9,13.8,30.9,30.9C105.9,66.3,104.3,71.8,101.5,76.5z',
	fillColor: '#0055a4',
	fillOpacity: 0.8,
	scale: 0.5,
	strokeWeight: 0
};

const mapConfig = {
	center: {
		lat: centerLat,
		lng: centerLng
	},
	zoom: 14,
	disableDefaultUI: true,
	scrollwheel: false
};


function initMap() {
	geocoder = new google.maps.Geocoder();

	map = new google.maps.Map(document.getElementById('map'), mapConfig);

	var infowindow;

	addresses.forEach((element, index) => {

		let contentString;

		if (element.web) {
			contentString = `<h3>${element.name}</h3>
							<address>
								${element.street} <br>
								${element.zip}
							</address>
							<a href=\"http://${element.web}\" target="_blank">${element.web}</a>`;
		} else {
			contentString = `<h3>${element.name}</h3>
							<address>
								${element.street} <br>
								${element.zip}
							</address>`;
		}


		var marker = new google.maps.Marker({
			position: element.position,
			map: map
		});


		google.maps.event.addListener(marker,'click', (function(){
			if (infowindow) {
				infowindow.close();
			}

			map.setCenter(marker.getPosition());

			infowindow = new google.maps.InfoWindow({
				content: contentString
			});

			infowindow.open(map, marker);
		}));

	});
}

function getPosition(address) {
	let loc=[];

	geocoder.geocode({
		address: address
	}, function(results, status){
		if (status == google.maps.GeocoderStatus.OK) {
			loc[0]=results[0].geometry.location.lat();
			loc[1]=results[0].geometry.location.lng();

			console.log(loc);
		} else {
			console.log('error');
		}
	});
}
