//$(document).ready(function(){
//	form.init();
//});
//
//var form = {
//	init: function() {
//		form.submitPostButton();
//	},
//
//	submitPostButton: function() {
//		let button = $('#post-button');
//
//		button.click(function() {
//			document.querySelectorAll("input[type=email]").required = false;
//		});
//	}
//};

var clickedSubmitButton = null;

$(document).ready(function()
{
	$('button[type=submit][name="submitMail"]').click(function()
	{
		clickedSubmitButton = true;
	});

	$('#form-stromfix').on("submit", function(e) {
		var email = $(this).find('input[name="email"]');
		if(clickedSubmitButton == true)
		{
			email.attr('required', 'required');
			email.attr('pattern', 'email');
			email.removeAttr('data-abide-ignore');
			if(email.parent().find('span.form-error').length == 0)
			{
				$('<span class="form-error">Bitte geben Sie ihre E-Mail Adresse ein.</span>').insertAfter(email);
			}
			$(this).foundation("validateForm");
		} else {
			email.removeAttr('required');
			email.removeAttr('pattern');
			email.removeAttr('data-invalid');
			email.removeAttr('class');
			email.parent().find('span.form-error').remove();
			email.parent().removeClass('is-invalid-label');
			email.attr('data-abide-ignore', 'data-abide-ignore');
			$(this).foundation('validateForm');
		}

		clickedSubmitButton = false;
	});
});
