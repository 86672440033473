let currentBreakpoint;

$(document).ready(function () {
	/**
	 * Call Foundation JS
	 */
	$(document).foundation();

	/**
	 * Call Fastclick JS
	 */
	FastClick.attach(document.body);

	/**
	 * Get the MediaQueries which are used in foundation
	 * @type {string} get current MediaQuery
	 */

	currentBreakpoint = Foundation.MediaQuery.current;

	header();
	slider.init();
	mobileOverlay();

	if (window.location.hash == '#success') {
		$('[data-abide-success]').show();
	}

});

$(window).resize(() => {
	header();
	pricingTable();

	currentBreakpoint = Foundation.MediaQuery.current;
});

$(window).load(function () {
	pricingTable();
});


var header = () => {
	let windowHeight = $(window).height();

	let headerTop = $('.header__top');
	let headerTopHeight = headerTop.height();
	let headerSlider = $('.header__slider');

	if (headerSlider.length) {
		let headerImageHeight = windowHeight - headerTopHeight;

		largeScreenAndBiggerHeight(headerSlider, headerImageHeight);
	}
};

var mobileOverlay = () =>
{
	let hamburger = $('.hamburger');
	let activeClass = 'is-active';

	let overlay = $('.mobile-overlay');

	hamburger.on('click', function () {
		hamburger.toggleClass(activeClass);
		overlay.toggleClass(activeClass);
	});

	let navPoints = overlay.find('a');

	navPoints.click(function () {
		if (overlay.hasClass(activeClass)) {
			overlay.removeClass(activeClass);
			hamburger.removeClass(activeClass);
		}
	});
};

var pricingTable = () => {
	let headlineHeight = 0;
	let headlineElement;

	let checklistHeight = 0;
	let checklistElement;

	if ($('.pricing-table').length) {
		$('.pricing-table').each(function () {
			let headline = $(this).find('.headline');

			if (headline.outerHeight() > headlineHeight) {
				headlineElement = this;
				headlineHeight = headline.outerHeight();
			}

			let checklist = $(this).find('.checklist--height');

			if (checklist[0].scrollHeight > checklistHeight) {
				checklistElement = this;

				checklistHeight = checklist.outerHeight();
			}
		});
	}

	largeScreenAndBiggerHeight($('.pricing-table .headline'), headlineHeight);
	largeScreenAndBiggerHeight($('.pricing-table .checklist--height'), checklistHeight);
};

var largeScreenAndBiggerHeight = (ele, height) => {
	if (currentBreakpoint != 'medium' && currentBreakpoint != 'xsmall' && currentBreakpoint != 'small') {
		ele.css('height', height);
	} else {
		ele.css('height', 'auto')
	}
};

var slider = {
	init: function () {
		slider.testimonial();
		slider.partner();
	},

	testimonial: function () {
		$('.content__slider--testimonial').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			pauseOnHover: true,
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 1500,
			prevArrow: '<button type="button" class="slick-prev slick-arrow"><span class="icon icon-angle-left"></span></button>',
			nextArrow: '<button type="button" class="slick-next slick-arrow"><span class="icon icon-angle-right"></span></button>'
		});
	},

	partner: function () {
		$('.content__slider--partner').slick({
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			adaptiveHeight: true,
			pauseOnHover: false,
			autoplay: true,
			autoplaySpeed: 4000,
			speed: 1500,
			prevArrow: '<button type="button" class="slick-prev slick-arrow"><span class="icon icon-angle-left"></span></button>',
			nextArrow: '<button type="button" class="slick-next slick-arrow"><span class="icon icon-angle-right"></span></button>',
			responsive: [
				{
					breakpoint: 970,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 420,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
	}
};

window.addEventListener('load', function () {
	window.cookieconsent.initialise({
		'palette': {
			'popup': {
				'background': '#1e376c'
			},
			'button': {
				'background': '#ffffff',
				'text': '#1e376c'
			}
		},
		'position': 'bottom-right',
		'content': {
			'message': 'Diese Website verwendet Cookies, um das beste Nutzererlebnis zu gewährleisten.',
			'dismiss': 'Verstanden',
			'link': 'Mehr erfahren',
			'href': 'http://www.stromfix-kulmbach.de/datenschutz.html'
		}
	})
});
